import { getUserImgSrc } from '#app/utils/misc'
import { Link } from '@remix-run/react'
import { type RootUser } from './layout.tsx'

export function NavUser({
	user,
	// isExternalRoute,
}: {
	user?: RootUser | null
	// isExternalRoute: boolean
}) {
	if (!user) {
		return null
	}

	return (
		<li className="-mx-6 mt-auto">
			<Link
				className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-foreground hover:bg-accent"
				to="/settings"
			>
				<img
					alt=""
					className="size-8 rounded-full"
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					src={getUserImgSrc(user.image?.id)}
				/>
				<span className="sr-only">Your profile</span>
				<span aria-hidden="true">{user.name}</span>
			</Link>
		</li>
	)
}
