import { type routeTypes } from '#app/root.tsx'
import { SearchHeader } from '#app/routes/resources+/search-header.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { type Theme } from '#app/utils/theme.server.ts'
import React from 'react'
import { twMerge } from 'tailwind-merge'

import { MarketingNav } from './marketing/marketing-nav.tsx'
import { Icon } from './ui/icon.tsx'

export function Header({
	isExternalUser,
	isLoggedIn = false,
	routeType,
	setSidebarOpen,
	theme,
}: {
	isExternalUser: boolean
	isLoggedIn?: boolean
	routeType: (typeof routeTypes)[number]
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
	theme: Theme | null
}) {
	return (
		<>
			<div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-background px-4 shadow-sm sm:px-6 lg:px-8">
				<button
					className={twMerge('-m-2.5 p-2.5 pr-0 text-primary xl:hidden')}
					onClick={() => setSidebarOpen(true)}
					type="button"
				>
					<span className="sr-only">Open sidebar</span>
					<Icon aria-hidden="true" name="menu" size="md" />
				</button>
				{['external', 'internal'].includes(routeType) ? (
					<>
						<div className="flex flex-1 justify-between self-stretch">
							<SearchHeader />
							<div />
							<div className="ml-2 self-center">
								<ThemeSwitch userPreference={theme} />
							</div>
						</div>
					</>
				) : (
					<MarketingNav
						isExternalUser={isExternalUser}
						isLoggedIn={isLoggedIn}
						theme={theme}
					/>
				)}
			</div>
		</>
	)
}
