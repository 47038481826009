import { cn } from '#app/utils/misc'
import { Link } from '@remix-run/react'

import { type NavItem } from './nav-items-data.tsx'
import { Icon } from './ui/icon.tsx'

export function NavItems({
	navItems,
}: {
	navItems: NavItem[]
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
	return (
		<li>
			<ul className="-mx-2 space-y-1">
				{navItems.map((item) => (
					<li key={item.name}>
						<Link
							className={cn(
								item.current
									? 'bg-accent text-foreground'
									: 'text-foreground hover:bg-accent',
								'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
							)}
							to={item.href}
						>
							<Icon
								aria-hidden="true"
								className="size-6 shrink-0"
								name={item.icon}
							/>
							{item.name}
						</Link>
					</li>
				))}
			</ul>
		</li>
	)
}
