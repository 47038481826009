import { modelNames, type ModelName } from '#app/utils/modelNames.ts'
import { models } from '#app/utils/models.ts'

import { type IconName } from './ui/icon.tsx'

export interface NavItem {
	current?: boolean
	href: string
	icon: IconName
	name: string
}

const internal = [
	...modelNames.map((name) => {
		const model = models[name]
		return {
			href: model.listUrl(),
			icon: 'icon' in model ? model.icon : 'building',
			name: model.displayNames.singular,
		}
	}),
] satisfies NavItem[]

// marketing
const marketingCommon = [
	// { name: 'Features', href: '/features', icon: 'envelope-closed' },
	// { name: 'Pricing', href: '/pricing', icon: 'camera' },
	{ href: '/contact', icon: 'envelope-closed', name: 'Contact' },
] satisfies NavItem[]

const marketingSidebar = [
	{ href: '/', icon: 'home', name: 'Home' },
	...marketingCommon,
	{ href: '/login', icon: 'log-in', name: 'Login' },
	{ href: '/contact', icon: 'log-in', name: 'Try ClientCruise' },
] satisfies NavItem[]

const marketingSidebarLoggedIn = [
	{ href: '/', icon: 'home', name: 'Home' },
	...marketingCommon,
] satisfies NavItem[]

const marketing = {
	sidebar: marketingSidebar,
	sidebarLoggedIn: marketingSidebarLoggedIn,
	top: marketingCommon,
}

const internalModels: ModelName[] = [
	'customer',
	'project',
	'feature',
	'useCase',
	'material',
	'training',
	'event',
	'persona',
	'metric',
]
const externalModels: ModelName[] = []

export function internalNavItems(): NavItem[] {
	const items: NavItem[] = internalModels.map((modelName) => {
		const model = models[modelName]
		return {
			href: model.listUrl(),
			icon: 'icon' in model ? model.icon : 'check',
			name: model.displayNames.plural,
		}
	})

	return [{ href: '/dashboard', icon: 'home', name: 'Dashboard' }, ...items]
}

export function externalNavItems(): NavItem[] {
	const items: NavItem[] = externalModels.map((modelName) => {
		const model = models[modelName]
		return {
			href: '/app' + model.listUrl(),
			icon: 'icon' in model ? model.icon : 'check',
			name: model.displayNames.plural,
		}
	})

	return [{ href: '/app', icon: 'home', name: 'Home' }, ...items]
}

// all
export const navItems = {
	auth: marketing,
	internal,
	marketing,
	other: marketing,
}

// function getNavItemsForUser(
// 	isExternalUser: boolean,
// 	isLoggedIn: boolean,
// 	pageType: 'auth' | 'external' | 'internal' | 'marketing' | 'other' = 'auth',
// ) {
// 	if (isExternalUser) {
// 		if (pageType !== 'external') {
// 			return [
// 				...marketing(),
// 				{ href: '/dashboard', icon: 'log-in', name: 'Open the app' },
// 			]
// 		}
// 		return externalNavItems()
// 	}

// 	if (isLoggedIn) {
// 		if (pageType !== 'internal') {
// 			return [
// 				...externalNavItems(),
// 				{ href: '/dashboard', icon: 'log-in', name: 'Open the app' },
// 			]
// 		}
// 		return internalNavItems()
// 	}

// 	return navItems[pageType]
// }
