import { ComboConform } from '#app/components/inputs/combo-conform.js'
import { navItems } from '#app/components/nav-items-data'
import { Button } from '#app/components/ui/button.tsx'
import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from '#app/components/ui/command.tsx'
import { search } from '#app/models/search.server.ts'
import { requireInternalUser } from '#app/utils/auth.server.ts'
import { removeNullishValues } from '#app/utils/lodash.ts'
import { cn } from '#app/utils/misc.js'
import { searchModels } from '#app/utils/modelNames'
import { models } from '#app/utils/models.ts'
import { getFormProps, getInputProps, useForm } from '@conform-to/react'
import { getZodConstraint, parseWithZod } from '@conform-to/zod'
import { json, type ActionFunctionArgs } from '@remix-run/node'
import { useFetcher, useNavigate } from '@remix-run/react'
import React from 'react'
import { useDebounceSubmit } from 'remix-utils/use-debounce-submit'
import { z } from 'zod'

const schema = z.object({
	search: z.string().optional(),
	searchType: z.array(z.string()).optional(),
})

export const searchObjTypes = searchModels.map((name) => {
	const stuff = {
		id: name,
		name: models[name].displayNames.plural,
	} as const
	return stuff
})

const modelNames = z.array(z.enum(searchModels).optional().nullable())

export async function action({ request }: ActionFunctionArgs) {
	const user = await requireInternalUser(request)
	const formData = await request.formData()
	const searchText = String(formData.get('search'))

	const searchType = removeNullishValues(
		String(formData.get('searchType'))
			.split(',')
			.filter((str) => str !== '' && str !== 'null'),
	)

	const objsFilter = removeNullishValues(
		modelNames.parse(searchType).map((type) => {
			return searchObjTypes.find(
				(searchObjType: { id: string; name: string }) =>
					searchObjType.id === type,
			)
		}),
	)

	if (searchText || objsFilter.length > 0) {
		const searchResults = await search({
			objectTypes: objsFilter,
			searchText,
			user,
		})
		return json({ searchResults })
	} else {
		return json({ searchResults: [] })
	}
}

export function SearchHeader() {
	// const [searchString, setSearchString] = React.useState('')
	const submit = useDebounceSubmit()
	const searcher = useFetcher<typeof action>({ key: 'search' })
	const [open, setOpen] = React.useState(false)
	const navigate = useNavigate()

	const [form, fields] = useForm({
		constraint: getZodConstraint(schema),
		id: `search-bar`,
		// lastResult,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema })
		},
	})

	React.useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				setOpen((open) => !open)
			}
		}

		document.addEventListener('keydown', down)
		return () => document.removeEventListener('keydown', down)
	}, [])

	const submitOptions = {
		action: '/resources/search-header',
		debounceTimeout: 1000,
		fetcherKey: 'search', // cancel any previous fetcher with the same key
		navigate: false, // use a fetcher instead of a page navigation
	}

	const runCommand = React.useCallback((command: () => unknown) => {
		setOpen(false)
		command()
	}, [])
	return (
		<>
			<searcher.Form
				action="/resources/search-header"
				method="post"
				{...getFormProps(form)}
				className="w-full"
			>
				<Button
					className={cn(
						'relative mt-3 w-full justify-start text-sm text-muted-foreground',
					)}
					onClick={() => setOpen(true)}
					variant="outline"
				>
					<span className="inline-flex">Search...</span>
					<kbd className="pointer-events-none absolute right-1.5 top-2.5 hidden h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex">
						<span className="text-xs">⌘</span>K
					</kbd>
				</Button>
			</searcher.Form>

			<CommandDialog onOpenChange={setOpen} open={open}>
				<CommandInput
					placeholder="Type a command or search..."
					{...getInputProps(fields.search, { type: 'text' })}
					onInput={(event) => {
						submit(event.currentTarget.form, submitOptions)
					}}
				/>
				<CommandList>
					<div className="p-2">
						<ComboConform
							items={searchObjTypes}
							meta={fields.searchType}
							// todo: submit on change
							// onChange={(event) => {
							// 	submit(event.currentTarget.form, submitOptions)
							// }}
							title="Type"
						/>
					</div>
					<CommandEmpty>No results found.</CommandEmpty>

					{/* {searcher.state === 'loading' && (
							<CommandLoading>Fetching results...</CommandLoading>
						)} */}

					<CommandGroup heading="Links">
						{[
							...externalLinks.filter(
								(link) =>
									!['Blog', 'Features', 'Log in', 'Sign up'].includes(
										link.name,
									),
							),
							...navItems.internal,
						].map((navItem) => (
							<CommandItem
								key={navItem.name + navItem.href}
								onSelect={() => {
									runCommand(() => navigate(navItem.href))
								}}
								value={navItem.name}
							>
								{navItem.name}
							</CommandItem>
						))}
					</CommandGroup>
					<CommandGroup heading="Objects">
						{searcher.data &&
							'searchResults' in searcher.data &&
							searcher.data.searchResults.map((item) => (
								<CommandItem
									key={item.name + item.href}
									onSelect={() => {
										runCommand(() => navigate(item.href))
									}}
									value={item.name}
								>
									{item.category} - {item.name}
								</CommandItem>
							))}
					</CommandGroup>
					<CommandSeparator />
					{/* <CommandGroup heading="Theme">
						<CommandItem onSelect={() => runCommand(() => setTheme('light'))}>
							<SunIcon className="mr-2 size-4" />
							Light
						</CommandItem>
						<CommandItem onSelect={() => runCommand(() => setTheme('dark'))}>
							<MoonIcon className="mr-2 size-4" />
							Dark
						</CommandItem>
						<CommandItem onSelect={() => runCommand(() => setTheme('system'))}>
							<LaptopIcon className="mr-2 size-4" />
							System
						</CommandItem>
					</CommandGroup> */}
				</CommandList>
			</CommandDialog>
		</>
	)
}

export interface NavItem {
	disabled?: boolean
	external?: boolean
	href?: string
	icon?: unknown
	label?: string
	name: string
}

const externalLinks = [
	{
		href: '/dashboard',
		name: 'Dashboard',
	},
	{
		href: '/docs',
		name: 'Docs',
	},
	{
		href: '/blog',
		name: 'Blog',
	},
	{
		href: '/login',
		name: 'Log in',
	},
	{
		href: '/signup',
		name: 'Sign up',
	},
] satisfies NavItem[]
