export function Logo() {
	return (
		<div className="flex shrink-0 items-center text-2xl font-medium">
			<div className="flex size-10 items-center justify-center rounded-full bg-primary text-xl font-bold text-secondary">
				CC
			</div>
			<span className="ml-2 text-2xl font-semibold text-primary">
				ClientCruise
			</span>
		</div>
	)
}
