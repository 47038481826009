import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { type Theme } from '#app/utils/theme.server.ts'
import { Link } from '@remix-run/react'

import { navItems } from '../nav-items-data.tsx'
import { Logo } from '../nav-logo.tsx'
import { Button } from '../ui/button.tsx'

export function MarketingNav({
	isExternalUser,
	isLoggedIn = false,
	theme,
}: {
	isExternalUser: boolean
	isLoggedIn?: boolean
	theme: Theme | null
}) {
	return (
		<div className="flex h-12 flex-1 justify-between align-middle">
			<div className="flex flex-row align-middle">
				<LogoButton />

				{navItems.marketing.top.map(({ href, name }) => (
					<Link key={name} to={href}>
						<Button
							className="hidden h-full pt-2 text-secondary-foreground sm:block lg:text-lg"
							variant="link"
						>
							{name}
						</Button>
					</Link>
				))}
			</div>
			<div className="align-center hidden h-12 justify-items-center sm:flex">
				<div className="mr-2 self-center">
					<ThemeSwitch userPreference={theme} />
				</div>
				{!isLoggedIn ? (
					<>
						<LoginButton />
						<TryButton />
					</>
				) : (
					<OpenAppButton isExternalUser={isExternalUser} />
				)}
			</div>
		</div>
	)
}

function LogoButton() {
	return (
		<Link className="" to="/">
			<div className="mr-4 flex h-full flex-col justify-center ">
				<Logo />
			</div>
		</Link>
	)
}

function LoginButton() {
	return (
		<Link to="/login">
			<Button
				className="mx-2 h-full px-2 text-secondary-foreground"
				variant="link"
			>
				Login
			</Button>
		</Link>
	)
}

function TryButton() {
	return (
		<Link className="flex items-center align-middle" to="/contact">
			<Button>Try ClientCruise</Button>
		</Link>
	)
}

function OpenAppButton({ isExternalUser }: { isExternalUser: boolean }) {
	return (
		<Link to={isExternalUser ? '/app' : '/dashboard'}>
			<div className="flex h-full flex-col justify-center ">
				<Button className="">Open the app</Button>
			</div>
		</Link>
	)
}
