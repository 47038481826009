import { type routeTypes } from '#app/root.tsx'
import { cn } from '#app/utils/misc.js'
import { type Theme } from '#app/utils/theme.server.ts'
import { type useOptionalUser } from '#app/utils/user.ts'
import {
	Dialog,
	DialogPanel,
	Transition,
	TransitionChild,
} from '@headlessui/react'
import { useLocation } from '@remix-run/react'
import { Fragment, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { Header } from './header.tsx'
import {
	externalNavItems,
	internalNavItems,
	navItems,
	type NavItem,
} from './nav-items-data.tsx'
import { NavItems } from './nav-items.tsx'
import { Logo } from './nav-logo.tsx'
import { NavUser } from './nav-user.tsx'
import { Icon } from './ui/icon.tsx'

export type RootUser = ReturnType<typeof useOptionalUser>

export function Layout({
	children,
	routeType,
	theme,
	user,
}: {
	children: React.ReactNode
	routeType: (typeof routeTypes)[number]
	theme: Theme | null
	user?: RootUser | null
}) {
	const [sidebarOpen, setSidebarOpen] = useState(false)
	const location = useLocation()

	let appNavItems: NavItem[]
	if (['auth', 'marketing', 'other'].includes(routeType)) {
		appNavItems = user
			? navItems.marketing.sidebarLoggedIn
			: navItems.marketing.sidebar
	} else if (routeType === 'external') {
		appNavItems = externalNavItems()
	} else if (routeType === 'internal') {
		appNavItems = user?.internal ? internalNavItems() : externalNavItems()
	} else {
		appNavItems = navItems.marketing.sidebar
	}

	const navItemsWithCurrent = appNavItems.map((item) => ({
		...item,
		current: location.pathname.startsWith(item.href),
	}))

	return (
		<>
			<div className="h-full">
				<Transition as={Fragment} show={sidebarOpen}>
					<Dialog
						as="div"
						className="relative z-50 xl:hidden"
						onClose={setSidebarOpen}
					>
						<TransitionChild
							as={Fragment}
							enter="transition-opacity ease-linear duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="transition-opacity ease-linear duration-300"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="fixed inset-0 bg-black/10" />
						</TransitionChild>

						<div className="fixed inset-0 flex">
							<TransitionChild
								as={Fragment}
								enter="transition ease-in-out duration-300 transform"
								enterFrom="-translate-x-full"
								enterTo="translate-x-0"
								leave="transition ease-in-out duration-300 transform"
								leaveFrom="translate-x-0"
								leaveTo="-translate-x-full"
							>
								<DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1 ">
									<TransitionChild
										as={Fragment}
										enter="ease-in-out duration-300"
										enterFrom="opacity-0"
										enterTo="opacity-100"
										leave="ease-in-out duration-300"
										leaveFrom="opacity-100"
										leaveTo="opacity-0"
									>
										<div className="absolute left-full top-0 flex w-16 justify-center pt-5">
											<button
												className="-m-2.5 p-2.5"
												onClick={() => setSidebarOpen(false)}
												type="button"
											>
												<span className="sr-only">Close sidebar</span>
												<Icon
													aria-hidden="true"
													className="text-primary"
													name="x"
													size="lg"
												/>
											</button>
										</div>
									</TransitionChild>
									{/* Sidebar component, swap this element with another sidebar if you like */}
									<MainArea
										navItems={navItemsWithCurrent}
										setSidebarOpen={setSidebarOpen}
										user={user}
									/>
								</DialogPanel>
							</TransitionChild>
						</div>
					</Dialog>
				</Transition>

				{/* Static sidebar for desktop */}
				<div
					className={cn(
						['external', 'internal'].includes(routeType) &&
							'hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-60 xl:flex-col',
					)}
				>
					{['external', 'internal'].includes(routeType) && (
						<MainArea
							navItems={navItemsWithCurrent}
							setSidebarOpen={setSidebarOpen}
							user={user}
						/>
					)}
				</div>
				<div
					className={cn(
						['external', 'internal'].includes(routeType) && 'h-full xl:pl-60',
					)}
				>
					{routeType !== 'external' && (
						<Header
							isExternalUser={user?.internal === false}
							isLoggedIn={!!user}
							routeType={routeType}
							setSidebarOpen={setSidebarOpen}
							theme={theme}
						/>
					)}

					<main
						className={twMerge(
							'h-full',
							routeType === 'external' && 'px-4 sm:px-6 py-4',
							['internal'].includes(routeType) &&
								'px-4 py-2 sm:px-6 sm:py-4 lg:px-8',
						)}
					>
						{children}
					</main>
				</div>
			</div>
		</>
	)
}

function MainArea({
	navItems,
	setSidebarOpen,
	user,
}: {
	navItems: NavItem[]
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
	user?: RootUser | null
}) {
	return (
		<div
			className="flex grow flex-col gap-y-5 overflow-y-auto bg-background px-6 pt-4 ring-1 ring-white/10"
			onClick={() => {
				setSidebarOpen(false)
			}}
		>
			<Logo />
			<nav className="flex flex-1 flex-col">
				<ul className="flex flex-1 flex-col gap-y-7">
					<NavItems navItems={navItems} setSidebarOpen={setSidebarOpen} />
					<NavUser user={user} />
				</ul>
			</nav>
		</div>
	)
}
